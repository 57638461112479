import React from "react"
import { Link, graphql } from "gatsby"

import tw from "twin.macro"
import Header from "../components/header"
import Footer from "../components/footer"
import Seo from "../components/seo"

const BlogContainer = tw.div`
    bg-white
`

const BlogPostTemplate = ({ data, location }) => {
  const post = data.markdownRemark
  const { previous, next } = data

  return (
    <BlogContainer>
      <Header />
      <Seo
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
      />
      <article
        className="blog-post"
        itemScope
        itemType="http://schema.org/Article"
        tw=""
      >
        <header>
          <div tw="px-4 py-12 mx-auto max-w-3xl text-left sm:px-6">
            <h1
              itemProp="headline"
              tw="text-5xl subpixel-antialiased font-medium leading-tight text-gray-900 md:leading-tight sm:text-5xl md:text-7xl"
            >
              <span tw="block my-2 text-base tracking-normal leading-tight text-gray-900 md:leading-tight sm:text-lg md:text-lg">
                {post.frontmatter.date}
              </span>
              <span tw="block tracking-tight leading-tight md:leading-tight">
                {post.frontmatter.title}
              </span>
            </h1>
          </div>
        </header>
        <section
          className="prose lg:prose-xl"
          tw="px-4 py-10 mx-auto max-w-3xl prose lg:prose-xl sm:px-6"
          dangerouslySetInnerHTML={{ __html: post.html }}
          itemProp="articleBody"
        />
      </article>
      <nav
        className="blog-post-nav"
        tw="px-4 py-20 mx-auto max-w-3xl text-lg font-normal leading-tight text-gray-900 sm:px-6 md:leading-tight sm:text-lg md:text-lg"
      >
        <ul
          style={{
            display: `flex`,
            flexWrap: `wrap`,
            justifyContent: `space-between`,
            listStyle: `none`,
            padding: 0,
          }}
        >
          <li tw="py-5">
            {previous && (
              <Link to={previous.fields.slug} rel="prev">
                ←{" "}
                <span tw="border-b border-transparent border-solid transition duration-300 ease-in-out hover:border-gray-900">
                  {previous.frontmatter.title}
                </span>
              </Link>
            )}
          </li>
          <li tw="py-5">
            {next && (
              <Link to={next.fields.slug} rel="next">
                <span tw="border-b border-transparent border-solid transition duration-300 ease-in-out hover:border-gray-900">
                  {next.frontmatter.title}
                </span>{" "}
                →
              </Link>
            )}
          </li>
        </ul>
      </nav>
      <Footer />
    </BlogContainer>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug(
    $id: String!
    $previousPostId: String
    $nextPostId: String
  ) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(id: { eq: $id }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "DD.MM.YYYY")
        description
      }
    }
    previous: markdownRemark(id: { eq: $previousPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
    next: markdownRemark(id: { eq: $nextPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
  }
`
