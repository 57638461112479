import * as React from 'react'
import tw from "twin.macro"
import "@fontsource/inter"


const HeaderContainer = tw.div`
    text-black bg-white transition-all duration-300 dark:bg-black dark:text-gray-200 flex flex-wrap justify-between px-5 py-8 w-full text-xl md:px-16 lg:px-20 md:py-12 lg:py-16
`

const Header = () => {
  return (
    <HeaderContainer>
          <div tw="w-3/4 md:w-1/2"></div>
          <div tw="text-right md:w-1/2">
            <nav tw="block text-xl leading-none">
              <div tw="inline-flex mr-5 transition-opacity md:mr-20">
                <a href="https://re.linkedin.com/in/thibaultlegloanic/" data-sal="fade" data-sal-delay="800" data-sal-duration="1000">
                  LinkedIn
                </a>
              </div>
              <div tw="inline-flex transition-opacity">
                <a href="https://www.researchgate.net/profile/Thibault-Le-Gloanic" data-sal="fade" data-sal-delay="800" data-sal-duration="1000">
                  Researchgate
                </a>
              </div>
            </nav>
          </div>
    </HeaderContainer>
  )
}

export default Header


